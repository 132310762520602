@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Cyberpunk";
  src: 
    url(/public/Cyberpunk-Regular.svg#Cyberpunk) format('svg'),
    url(/public/Cyborg-Punk.ttf) format('truetype');
  font-weight: normal; 
  font-style: normal;
}

.glitch-text {
  font-family: Cyberpunk;
  color: white;
  -webkit-text-stroke: 5px black;
  font-size: 10em;
  color: #ea00d9;
  text-shadow: 5px 5px 10px #711c91;
}

.glitch-text-animation {
  animation: glitch 10s step-end infinite alternate, glitch-skew 10s step-end infinite alternate;
}

@keyframes glitch {
  13% { 
    clip-path: polygon(90% 55%,20% 97%,85% 42%,20% 62%,6% 55%,76% 74%,68% 67%,49% 32%,47% 41%,2% 16%,95% 73%,68% 14%,35% 81%,31% 57%,29% 24%,57% 63%,52% 91%,45% 82%,6% 81%,82% 88%,30% 61%,70% 18%,15% 36%,16% 67%,74% 84%,21% 81%);
  }
  
  53% { 
    clip-path: polygon(46% 96%,51% 63%,92% 0%,47% 85%,26% 37%,15% 6%,16% 15%,64% 28%,69% 88%,50% 49%,29% 17%,91% 74%,54% 36%,85% 94%,89% 92%,96% 22%,47% 75%,51% 12%,12% 37%,1% 41%,63% 33%,90% 52%,48% 18%,5% 56%,77% 80%,60% 48%);
  }
  
  37% {
    clip-path: polygon(73% 96%,32% 94%,26% 33%,40% 16%,69% 28%,72% 42%,32% 33%,22% 3%,55% 64%,79% 57%,57% 39%,0% 44%,27% 36%,17% 32%,47% 35%,53% 10%,65% 96%,72% 51%,15% 56%,61% 21%,66% 29%,33% 7%,8% 86%,47% 89%,43% 56%,98% 7%);
  }
  
  62% {
    clip-path: polygon(44% 98%,91% 91%,90% 18%,72% 50%,63% 5%,36% 64%,23% 8%,43% 19%,88% 86%,7% 22%,37% 99%,71% 21%,76% 50%,100% 73%,5% 41%,65% 26%,19% 55%,36% 5%,11% 83%,28% 48%,36% 36%,9% 64%,64% 22%,66% 38%,80% 68%,69% 76%);
  }
  
  78%{
    clip-path: polygon(24% 46%,40% 79%,28% 54%,98% 82%,87% 67%,36% 71%,37% 29%,89% 26%,89% 21%,8% 8%,61% 35%,4% 23%,90% 77%,46% 98%,82% 6%,76% 94%,17% 52%,59% 22%,76% 92%,39% 44%,74% 97%,16% 6%,89% 84%,80% 52%,85% 55%,7% 91%);
  }
    14%,39%, 56%, 64%, 79%{ 
    clip-path: none;
  }
}

@keyframes glitch-skew {
  13% { 
    transform: skew(10deg, 10deg) scale(.6);
  }
  
  53% { 
    transform: skew(20deg, 30deg) scale(.6);
  }
  
  37% {
    transform: skew(-83deg, 17deg) scale(0.6);
  }
  
  78%{
    transform: skew(80deg, 18deg) scale(0.6);
  }
    14%,39%, 56%, 64%, 79%{ 
    transform: none;
  }
}

progress[value] {
  appearance: none;
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5) inset;
}

progress[value]::-webkit-progress-value {
  border-radius: 5px;
  background-color: #ea00d9;
}